import { v4 as uuid } from 'uuid'
import { getTruncatedFilename, getFileChecksum, cleanupWorkerPool } from '@/helpers/files'

import DocumentManagerService from '@/services/Documents/DocumentsManagerService'
import DocumentVersionsService from '@/services/Documents/DocumentVersionsService'

let UserHasBeenWarned = false

export default {
	data: function () {
		return {
			documentService: DocumentManagerService,
			documentVersionService: DocumentVersionsService,
			isMergeDialogOpen: false,
			itemsUploaderMixinMaxFilenameLength: 255,
			mergePreferences: {}
		}
	},
	beforeDestroy() {
		cleanupWorkerPool()
	},
	methods: {
		___processEntries: async function(vendor, targetedFolder, isRoot, entries) {
			// Load folder content once before processing any entries
			await this.___loadFolderContent(vendor.id, targetedFolder)
			const promises = []
			
			for (let i = 0; i < entries.length; i++) {
				let promise = Promise.resolve()
				let entry = null
				try {
					entry = await this.___validateEntry(targetedFolder, entries[i])
				} catch (error) {
					if (error.message !== 'Cancelled') {
						throw error
					}
				}
				if (entry instanceof File) {
					promise = this.___handleFileObjectEntry(targetedFolder, isRoot, entry)
				} else if (entry?.isFile) {
					promise = this.___handleIsFileEntry(targetedFolder, isRoot, entry)
				} else if (entry?.isDirectory) {
					promise = this.___handleDirectoryEntry(vendor, targetedFolder, entry)
				}
				if (entry) {
					promises.push(promise)
				}
			}

			return await Promise.all(promises)
		},
		// eslint-disable-next-line sonarjs/cognitive-complexity
		___validateEntry: async function (targetedFolder, entry) {
			const nodeName = getTruncatedFilename(entry.name)
			const existingNodeWithSameName = this.___getExistingNodeWithSameName(targetedFolder, nodeName)

			if (existingNodeWithSameName && !entry.is_manually_replicable) {
				const transactionId = entry.transactionId
				let isMergePreferenceSelected = transactionId && this.mergePreferences.hasOwnProperty(transactionId)
				if (!isMergePreferenceSelected) {
					await this.___waitForMergePrompt()
					isMergePreferenceSelected = transactionId && this.mergePreferences.hasOwnProperty(transactionId)
				}

				if (isMergePreferenceSelected) {
					entry.merge = this.mergePreferences[transactionId]
				} else {
					existingNodeWithSameName.isFolder = (entry?.isDirectory || entry.type === 'folder') ?? false
					const answer = await this.___promptForMerge(existingNodeWithSameName, entry)
					if (answer === 'Cancelled') {
						throw new Error('Cancelled')
					}
					entry.merge = answer?.merge ?? false
					if (answer?.recursive) {
						this.mergePreferences[transactionId] = answer.merge
					}
				}
				entry.sibblingNodeId = existingNodeWithSameName?.id
			}

			return entry
		},
		___uploadItems: function(vendor, targetedFolder, items, isRootCall = true) {
			UserHasBeenWarned = false
			const entries = items && items instanceof FileList ? Array.from(items) : items

			if (!entries?.length) {
				return Promise.resolve()
			}

			// Always pre-scan at root level to get total counts
			if (isRootCall) {
				return this.___preProcessItems(targetedFolder, entries).then(processedItems => {
					// Emit UPLOAD_STARTED with total counts before any uploads begin
					if (processedItems.totalFiles > 0) {
						this.appEventBus.emit(this.appEvents.UPLOAD_STARTED, {
							folder: targetedFolder,
							items: processedItems.items,
							totalFiles: processedItems.totalFiles,
							totalSize: processedItems.totalSize,
							vendor: vendor
						})
					}

					// Now start the actual upload process
					return this.___processEntries(vendor, targetedFolder, targetedFolder.is_root, processedItems.items)
						.finally(() => {
							cleanupWorkerPool()
							targetedFolder.mergeChildren = undefined
							this.appEventBus.emit(this.appEvents.UPLOAD_ENDED)
						})
				})
			}

			// For recursive calls, just process the items directly
			return this.___processEntries(vendor, targetedFolder, targetedFolder.is_root, entries)
		},
		___handleFileObjectEntry: function (targetedFolder, isRoot, item) {			
			let result = Promise.resolve()
			if (!isRoot) {
				result = this.___createDocument(targetedFolder, item)
			} else {
				this.___warnUser()
			}
			return result
		},
		___handleIsFileEntry: function (targetedFolder, isRoot, item) {			
			let result = Promise.resolve()
			if (!isRoot) {
				result = new Promise(resolve => {
					item.file(
						file => {
							resolve(this.___createDocument(targetedFolder, file))
						},
						error => {
							throw error
						}
					)
				})
			} else if (!UserHasBeenWarned) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('documents.errors.no_document_on_root_folder'))
				UserHasBeenWarned = true 	
			}
			return result
		},
		__readEntriesPromise: async function (directoryReader) {
			return await new Promise((resolve, reject) => {
				directoryReader.readEntries(resolve, reject)
			})
		},
		__readAllDirectoryEntries: async function (directoryReader) {
			let entries = []
			let readEntries = await this.__readEntriesPromise(directoryReader)
			while (readEntries.length > 0) {
				entries = entries.concat(readEntries)
				readEntries = await this.__readEntriesPromise(directoryReader)
			}
			
			return entries
		},
		___handleDirectoryEntry: async function (vendor, targetedFolder, item) {
			const folder = await this.___createFolder(vendor, targetedFolder, {
				name: item.name,
				isFolder: true,
				id: item.id,
				merge: item.merge ?? false,
				sibblingNodeId: item.sibblingNodeId,
				transactionId: item.transactionId,
			})
			
			let result = null
			if (folder) {
				await this.___loadFolderContent(vendor.id, folder)
				let children = []
				if (item.children && Array.isArray(item.children)) {
					children = item.children
				} else {
					const directoryReader = item.createReader()
					children = await this.__readAllDirectoryEntries(directoryReader)
				}

				result = this.___uploadItems(vendor, folder, children, false)
			}

			return result
		},
		___handleDocumentVersion: function(targetedFolder, rawDocument, existingNodeWithSameName) {
			if (existingNodeWithSameName && !targetedFolder.mergeChildren && !rawDocument?.is_manually_replicable) {
				return new Promise(resolve => {
					existingNodeWithSameName.isFolder = false
					this.___promptForMerge(existingNodeWithSameName, rawDocument).then(answer => {
						if (answer === 'Cancelled') {
							resolve('Cancelled')
						} else {
							resolve(this.___createDocumentOrVersion(
								targetedFolder,
								rawDocument,
								answer.merge,
								existingNodeWithSameName.id
							))
						}
					})
				})
			}

			return this.___createDocumentOrVersion(
				targetedFolder,
				rawDocument,
				existingNodeWithSameName && targetedFolder.mergeChildren,
				existingNodeWithSameName?.id
			)
		},

		___createDocument: function (targetedFolder, rawDocument, retry = true) {
			if (!targetedFolder?.id) {
				return Promise.resolve()
			}

			return this.___createDocumentOrVersion(
					targetedFolder,
					rawDocument,
					rawDocument.merge,
					rawDocument.sibblingNodeId
				)
				.then(documentLatestVersion => {
					if (!documentLatestVersion) {
						return
					}
					return getFileChecksum(rawDocument).then(fileChecksum => {
						return this.___validateChecksum(documentLatestVersion, fileChecksum).catch(error => {
							const documentToDelete = this.documentService.findNodeInStructure(documentLatestVersion.document_id)
							return this.___deleteDocumentOrVersion(documentToDelete, documentLatestVersion).finally(() => {
								return this.___retryUploadDocumentOnError(error, targetedFolder, rawDocument, retry)
							})
						})
					})
				})
				.catch(error => {
					return this.___retryUploadDocumentOnError(error, targetedFolder, rawDocument, false)
				})
		},
		___handleFolderMerge: function(vendor, targetedFolder, rawFolder) {
			let subResult = Promise.resolve()
			if (rawFolder.merge && rawFolder.sibblingNodeId) {
				const node = this.documentService.findNodeInStructure(rawFolder.sibblingNodeId)
				if (node) {
					node.mergeChildren = true
					subResult = Promise.resolve(node)
				}
			} else {
				subResult = this.documentService.createFolder(vendor.id, targetedFolder, { name: rawFolder.name })
			}
			return Promise.resolve(subResult).then(() => {
				this.appEventBus.emit(this.appEvents.COMPLETE_UPLOAD_PROGRESS, rawFolder.id)
				return subResult
			})
		},
		___createFolder: function (vendor, targetedFolder, rawFolder) {
			if (!targetedFolder?.id) {
				return Promise.resolve()
			}

			return this.___handleFolderMerge(vendor, targetedFolder, rawFolder)
		},
		__moveNode: async function (targetedFolder, rawFolder, moveAction) {
			let folder
			rawFolder.transactionId = uuid()
			await this.___loadFolderContent(this.vendorId, targetedFolder)
			try {
				folder = await this.___validateEntry(targetedFolder, rawFolder)
			} catch (error) {
				if (error.message !== 'Cancelled') {
					throw error
				}
				return
			}

			const oldParent = rawFolder.parent
			if (oldParent) {
				oldParent.remove(rawFolder)
			}
			if (folder.merge) {
				targetedFolder = this.___getExistingNodeWithSameName(targetedFolder, folder.name)
				this.___mergeFolders(targetedFolder, folder, moveAction)
			} else {
				targetedFolder.add(folder)
				moveAction(this.vendorId, oldParent.id, folder, null, { folder_id: targetedFolder.id }).catch(error => {
					targetedFolder.remove(folder)
					oldParent.add(folder)
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error.message)
				})
			}
		},

		___loadFolderContent: async function (vendorId, rawFolder) {
			if (rawFolder.children) {
				return Promise.resolve()
			}

			const parameters = {
				filters: {
					with_content: true
				},
				fields: ['counter', 'folders', 'folders.has_sub_folders', 'has_sub_folders', 'documents']
			}

			return this.documentService.getFolder(vendorId, rawFolder.id, parameters, true, false)
		},
		___mergeChild: async function (targetedFolder, nodeChild) {
			let node
			try {
				node = await this.___validateEntry(targetedFolder, nodeChild)
			} catch (error) {
				if (error.message !== 'Cancelled') {
					throw error
				}
				return
			}

			if (node.merge && node.sibblingNodeId) {
				let subResult = this.documentService.findNodeInStructure(node.sibblingNodeId)
				this.___mergeFolders(subResult, nodeChild)
			} else {
				targetedFolder.add(nodeChild)
			}
		},
		___mergeFolders: function (targetedFolder, rawFolder) {
			const oldParent = rawFolder.parent
			targetedFolder.mergeChildren = true
			if (oldParent) {
				oldParent.remove(rawFolder)
			}
			let movedNodes = []
			const getFolderContent = this.___loadFolderContent(this.vendorId, rawFolder)
			getFolderContent.then(() => {
				if (rawFolder.children.length > 0) {
					let result = Promise.all(rawFolder.children.map(nodeChild => {
						let action = Promise.resolve()
						let moveAction = null
						if (nodeChild.is_folder) {
							nodeChild.isFolder = true
							moveAction = this.documentService.updateFolderFolder
							this.___mergeChild(targetedFolder, nodeChild)
						} else if (nodeChild.is_document) {
							targetedFolder.add(nodeChild)
							moveAction = this.documentService.updateFolderDocument
						}
						movedNodes.push(nodeChild)
						action = moveAction(this.vendorId, rawFolder.id, nodeChild, null, { folder_id: targetedFolder.id })
						return action
					})
					)
					result.then(() => {
						this.documentService.deleteFolder(this.vendorId, rawFolder)
					}).catch(error => {
						movedNodes.forEach(nodeToRemove => {
							targetedFolder.remove(nodeToRemove)
						})
						oldParent.add(rawFolder)
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error.message)
					})
				} else {
					targetedFolder.add(rawFolder)
				}
			})
		},
		___normalizeUnicodeEncoding: function (text) {
			return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		},
		___getExistingNodeWithSameName: function (targetedFolder, name) {
			let result = null;
			let normalizedName = this.___normalizeUnicodeEncoding(name).toLowerCase();

			if (targetedFolder.children) {
				result = targetedFolder.children.find(siblingNode => {
					let normalizedSiblingName = this.___normalizeUnicodeEncoding(siblingNode.name).toLowerCase();
					return normalizedSiblingName.trim() === normalizedName.trim();
				});
			}

			return result;
		},
		___promptForMerge: function (nodeToUpload, upload) {
			this.isMergeDialogOpen = true
			return new Promise(resolve => {
				this.appService.openDialog({
					attrs: {
						value: nodeToUpload
					},
					component: () => import('@/components/Documents/Upload/ShouldMergeDialog'),
					listeners: {
						close: () => {
							// Cancel all uploads and close the progress window when user cancels from merge prompt
							this.appEventBus.emit(this.appEvents.CANCEL_ALL_UPLOADS, upload.id)
							this.isMergeDialogOpen = false
							resolve('Cancelled')
						},
						input: (event) => {
							this.isMergeDialogOpen = false
							resolve(event)
						}
					}
				})
			})
		},
		___waitForMergePrompt: function () {
			return new Promise(resolve => {
				if (this.isMergeDialogOpen) {
					setTimeout(function () {
						this.___waitForMergePrompt()
							.then(resolve)
					}.bind(this), 1000)
				} else {
					resolve()
				}
			})
		},
		___informOfChecksumFailure: function (nodeToUpload) {
			return new Promise(resolve => {
				this.appService.openDialog({
					attrs: {
						value: nodeToUpload
					},
					component: () => import('@/components/Documents/Upload/ChecksumFailedDialog'),
					listeners: {
						close: function () {
							resolve('Cancelled')
						},
						input: function (event) {
							resolve(event)
						}
					}
				})

			})
		},
		___createDocumentOrVersion: async function (targetedFolder, rawDocument, merge, documentId = null) {
			const vendorId = targetedFolder.vendor_id;
			let result
			if (merge) {
				result = await this.documentVersionService.createDocumentVersion(vendorId, documentId, rawDocument)
			} else {
				const createdDocument = await this.documentService.createFolderDocument(vendorId, targetedFolder.id, rawDocument)
				await this.documentVersionService.getDocumentVersions(vendorId, createdDocument, { filters: { latest: true } })
				result = createdDocument.latestVersion
			}
			return result
		},
		___deleteDocumentOrVersion: function (currentDocument, documentVersion) {
			let result = null
			const previousDocumentVersion = currentDocument.previousVersion(documentVersion)
			if (previousDocumentVersion) {
				result = this.documentVersionService.deleteDocumentVersion(this.vendorId, currentDocument.id, documentVersion)
			} else {
				result = this.documentService.deleteDocument(this.vendorId, currentDocument, { forceDelete: true })
			}
			return result
		},
		___retryUploadDocumentOnError: function (error, targetedFolder, rawDocument, retry = false) {
			if (!retry) {
				throw error
			}
			return this.___createDocument(targetedFolder, rawDocument, false)
		},
		___validateChecksum: function (documentVersion, fileChecksum) {
			const document = this.documentService.findNodeInStructure(documentVersion.document_id)
			return this.documentVersionService
				.getDocumentVersionChecksum(this.vendorId, document.id, document.latestVersion.id, { file_signature: fileChecksum })
				.then(() => {
					return document.latestVersion
				})
		},
		___preProcessItems: async function(targetedFolder, items, transactionId = null) {
			let processedItems = {
				items: [],
				totalFiles: 0,
				totalSize: 0
			}
			const isRootFolder = !targetedFolder.folder_id

			if (!transactionId) {
				transactionId = uuid()
			}

			for (const item of items) {
				if (isRootFolder 
					&& (item instanceof File || item?.isFile)) {
					this.___warnUser()
					continue
				}
				if (item instanceof File) {
					const processedFile = new File([item], item.name, {
						type: item.type,
						lastModified: item.lastModified
					})
					processedFile.id = uuid()
					processedFile.transactionId = transactionId
					processedItems.items.push(processedFile)
					processedItems.totalFiles++
					processedItems.totalSize += item.size
				} else if (item?.isFile) {
					const file = await new Promise((resolve, reject) => {
						item.file(resolve, reject)
					})
					const processedFile = new File([file], file.name, {
						type: file.type,
						lastModified: file.lastModified
					})
					processedFile.id = uuid()
					processedFile.original_name = item.name
					processedFile.path = item.fullPath || item.name
					processedFile.transactionId = transactionId
					processedItems.items.push(processedFile)
					processedItems.totalFiles++
					processedItems.totalSize += file.size
				} else if (item?.isDirectory) {
					const directoryReader = item.createReader()
					const entries = await this.__readAllDirectoryEntries(directoryReader)
					const subProcessedItems = await this.___preProcessItems({folder_id:true}, entries, transactionId)

					const dirEntry = {
						id: uuid(),
						name: item.name,
						path: item.fullPath || item.name,
						isDirectory: true,
						children: subProcessedItems.items,
						transactionId: transactionId
					}
					processedItems.items.push(dirEntry)
					processedItems.totalFiles += subProcessedItems.totalFiles
					processedItems.totalSize += subProcessedItems.totalSize
				}
			}

			return processedItems
		},
		___warnUser: function () {
			if (!UserHasBeenWarned) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('documents.errors.no_document_on_root_folder'))
				UserHasBeenWarned = true
			}
		}
	}
}

